
import React, { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { isEmpty } from 'lodash'
import { useRequest, dispatchAsync } from '@friday/async'
import { Button, message, Row, Space, DatePicker, Form, Input ,InputNumber, Radio, Modal } from 'antd'
import { apis } from "src/apiStore";
import ImgPreview from 'src/components/ImgPreview'

const Create: React.FC<any> = (props: any) => {

    const { drawerProps } = props

    const [ form ] = Form.useForm()

    const id = drawerProps?.record?.id

    const data = drawerProps?.record

	const onFinish = async (value, errors)  => {
        if(!isEmpty(errors)) return
        const payload = {
            ...value,
            id
        }
        const { error } = await dispatchAsync(apis.admin.contentAudit(payload))
        if ( error )return 
        message.success( '操作成功！')
        drawerProps.onFinishAndRevalidate()
    }

	return (
		<Modal 
			title={'内容审核'}
			width={800}
			visible={drawerProps.visible}
			onCancel={drawerProps.onClose}
            okText="确定"
            cancelText="取消"
            onOk={() => form.submit()}
		>
           <Form
                form={form}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish as any}
           >
                <Form.Item 
                    label='内容标题'
                >
                    {data.title}
                </Form.Item>

                <Form.Item 
                    label='作者信息'
                >
                    <img src={data.author.avatar} style={{ width: 30, height: 30, borderRadius: 10, marginRight: 5 }} />
                    {data.author.nickname}
                </Form.Item>

                <Form.Item 
                    label='内容段落'
                >
                    <div style={{maxHeight: 300, overflow: 'auto'}}>
                        {data.paragraphs.map((item, index)=> {
                            return(
                                <div key={index} style={{marginBottom: 8}}>
                                    <div>{item.text}</div>  
                                    <Space>
                                        {item.files.map((i, k) => {
                                            return <ImgPreview url={i.fileUrl} list={[i.fileUrl]} ></ImgPreview>
                                        })}
                                    </Space>
                                </div> 
                            ) 
                        })}
                    </div>
                </Form.Item>

                <Form.Item label='审核类型' name='state' rules={[{ required: true, message: '请审核' }]}>
                    <Radio.Group>
                        <Radio value={2}>通过</Radio>
                        <Radio value={3}>拒绝</Radio>
                   </Radio.Group>
                </Form.Item>
           </Form>
		</Modal>
	)
}

export default Create