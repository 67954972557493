
import React, { useMemo } from 'react'
import { useImmer } from 'use-immer'
import { isEmpty } from 'lodash'
import { useRequest, dispatchAsync } from '@friday/async'
import { Button, message, Row, Space, Spin, Form, Input ,Modal, Radio } from 'antd'
import UploadImg from '../../../components/UploadImg'
import { apis } from "src/apiStore";
import { getArrUpload, transformArrUpload } from "src/pages/admin/utils"
import { useConfiguration } from '@friday/core'


const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList
}

const Create: React.FC<any> = (props: any) => {

    const { drawerProps } = props
    
    const id = drawerProps?.record?.id

    const data = drawerProps?.record

    const [ form ] = Form.useForm()

	const onFinish = async (value, errors)  => {
        if(!isEmpty(errors)) return
        const payload = {
            ...value,
        }
        const { error } = await dispatchAsync(apis.admin.topicAudit({id, ...payload}))
        if ( error )return 
        message.success('操作成功！')
        drawerProps.onFinishAndRevalidate()
    }
 

	return (
		<Modal 
			title={'话题审核'}
			width={800}
			visible={drawerProps.visible}
			onCancel={drawerProps.onClose}
            okText="确定"
            cancelText="取消"
            onOk={() => form.submit()}
		>
           <Form
                form={form}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ type: 1 }}
                onFinish={onFinish as any}
           >
                <Form.Item 
                    label='话题名称'
                >
                    {data.name}
                </Form.Item>

                <Form.Item 
                    label='话题封面图'
                >
                    <img src={data.cover} style={{ width: 80, height: 80, borderRadius: 10 }} />
                </Form.Item>

                <Form.Item 
                    label='话题简介'
                >
                    {data.content || '--'}
                </Form.Item>

                <Form.Item label='审核类型' name='state' rules={[{ required: true, message: '请审核' }]}>
                    <Radio.Group>
                        <Radio value={2}>通过</Radio>
                        <Radio value={3}>拒绝</Radio>
                   </Radio.Group>
                </Form.Item>
                
           </Form>
		</Modal>
	)
}

export default Create