
import React from 'react'
import { Switch, Route, Router, Redirect } from '@friday/router'
import NotFound from 'src/pages/exception/NotFound'
import AdminRouter from 'src/pages/admin/ModuleRouter'

import 'src/styles/index.less'

const App = ({ history }) => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/404"  component={NotFound} />  
        <Route path="/admin" component={AdminRouter} />  
        <Redirect to='/admin' />
      </Switch>
    </Router>
  )
}

export default App
