import React from "react";
import { Divider, Space, Modal, Button, message } from 'antd'
import { Link } from '@friday/router'
import { dispatchAsync } from '@friday/async'
import { apis } from "src/apiStore";
import { get } from 'lodash'
import ImgPreview from 'src/components/ImgPreview'

import { useConfiguration } from '@friday/core'

export const statusMap = {
    0: '全部',
    1: "待审核",
    2: "已通过",
    3: "已拒绝"
}

const useColumns = (drawerProps, revalidate) => {

   
    return React.useMemo(() => {
        const columns = [{
            title: '话题ID',
            dataIndex: 'id'
        }, 
        {
            title: '话题名称',
            dataIndex: 'name'
        }, {
            title: '封面图',
            dataIndex: 'cover',
            render: (txt) => {
                return (
                    <ImgPreview 
                        url={txt}
                    />
                )
            }
        }, {
            title: '内容数量',
            dataIndex: 'nums'
        }, {
            title: '审核状态',
            dataIndex: 'status',
            render: ( text) => statusMap[text]
        },{
            title: '创建时间',
            dataIndex: 'createdAt',
        },{
            title: '更新时间',
            dataIndex: 'modifiedAt',
        },{
            title: '操作',
            dataIndex: 'opr',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Space split={<Divider />}>  
                        <Button type='primary' size={'small'} onClick={() => drawerProps.onOpen(record)}>
                            审核
                        </Button>
                    </Space>
                )
            }
        }]
    
        return columns
    
    }, [])
} 

export default useColumns