
import configuration_dev from './configuration.dev'
import configuration_pro from './configuration.pro'


const configurations = [
	configuration_dev,
	configuration_pro
]

export default configurations