
import React, { useEffect, useRef } from 'react';
import { Select, SelectProps } from 'antd';
import { useImmer } from 'use-immer';
import { debounce, throttle, isFunction, findIndex, isNumber, isString, isEqual } from 'lodash';
import { useRequest } from '@friday/async';

type Value = any;

type CanRequest = boolean | (() => boolean);

type paginationProps = {
	page: number,
	pageSize: number,
	// searchKey: any
};

interface MapObject {
	[x: string]: any
}

export interface AsyncSelectPlusProps extends SelectProps {
	maxTagCount?: number | 'responsive';
	// 请求api
	api?: any;
	// value 值,
	// tip: hasAllField true的时候设置value 默认为'' ，默认选中全部
	// 否则value 为undefined
	// 在重置value的时候也要知道这个逻辑
	value?: Value;
	onChange?: (value: Value) => void;
	// 是否显示全部项
	hasAllField?: boolean;
	// 全部项的label
	allSelectLabel?: string;
	style?: React.CSSProperties;
	className?: string;
	placeholder?: string;
	// api 额外的参数
	extensionParams?: MapObject;
	// 额外参数改变清空选择结果
	extensionChangeClear?: boolean;
	// 是否请求
	canRequest?: CanRequest;
	// onchange 之前
	onBeforeChange?: (value: Value, data: any) => void;
	// onchange 之后
	onAfterChange?: (value: Value, data: any, item?) => void;
	// response 之后
	onResponseChange?: (data: any) => void;
	// value reponse 都change
	onBothChange?: (value: Value, data: any) => void;
	dropdownMatchSelectWidth?: number,
	// 禁止某些选项
	disabledFiled?: any[],
	// 
	disabled?: boolean;

	labelKey?: string;

	labelValueKey?: string;

	searchKey?: string;

	onBlur?: React.FocusEventHandler<HTMLElement>;
}

const AsyncSelectPlus: React.FC<AsyncSelectPlusProps> = React.memo((props) => {

	const {
		// 异步获取数据api
		api,
		// select 组件style
		style,
		// select onchange 时间
		onChange,
		// select placeholder
		placeholder,
		// onchange 触发之前的生命周期
		onBeforeChange,
		// onchange 触发之后的生命周期
		onAfterChange,
		// 异步获取数据api 触发之后的生命周期
		onResponseChange,
		// 异步获取数据api触发之后和value更新的生命周期
		onBothChange,
		// 是否拉取异步数据
		canRequest = true,
		// 是否显示全部选项
		hasAllField = true,
		// 异步数据的扩展字段
		extensionParams = {},
		extensionChangeClear,
		// select dropdownMatchSelectWidth
		dropdownMatchSelectWidth,
		// 全部选项的label
		allSelectLabel = '全部选择',
		// select name
		className,
		// 禁止某些选项
		disabledFiled = [],
		disabled = false,
		labelKey = 'name',
		labelValueKey = 'id',
		searchKey = 'searchKey',
		onBlur,
		mode,
		...rest
	} = props;

	// 如果显示全部默认值为‘’，默认选择全部，否则不选
	const defaultVal = hasAllField ? '' : undefined;
	const { value = defaultVal } = props;

	// 异步数据的store
	const [datas, setDatas] = React.useState([] as any);
	// 异步数据配置
	const [pagination, setPagination] = useImmer<paginationProps>({
		page: 1,
		pageSize: 10,
		[searchKey]: '',
	});

	const prevParams = useRef(extensionParams);

	const payload = { ...pagination, ...extensionParams };

	// 是否拉取异步数据
	const request = React.useMemo(() => {
		return canRequest;
	}, [canRequest]);

	const { responseArray: response, isValidating } = useRequest(request ? (api as any)(payload) : null);

	const { data, total = 0 } = response as any;

	// 异步数据分页时push数据而不是set数据
	// 生命周期onResponseChange在异步数据push后执行
	React.useEffect(() => {
		const { data } = response;

		let nextData = [] as any;
		nextData = pagination.page === 1 ? data : [...datas, ...data];

		setDatas(nextData);
		onResponseChange && onResponseChange(datas);
	}, [response]);

	// onBothChange， 异步数据更新和value更新触发
	React.useEffect(() => {
		onBothChange && onBothChange(value, datas);
	}, [datas, value]);

	// select value 更改 触发生命周期
	const onSelectChange = (val, option) => {
		const nextVal = val || '';
		onBeforeChange && onBeforeChange(nextVal, data);
		onChange && onChange(nextVal);
		onAfterChange && onAfterChange(nextVal, data, option);
	};
	// 查找逻辑
	const onSerach = debounce((v) => {

		// 查找条件 value 不在当前数据源， 且value 不为空
		const hasIn = findIndex(datas, (d: any) => {
			return (d[labelKey] || '').indexOf(v) > -1 || d[labelValueKey] === v;
		});
		if (hasIn > -1 && v !== '') return;
		if (isString(v) || isNumber(v)) {
			setPagination(draft => {
				draft[searchKey] = v;
				draft.page = 1;
			});
		}
	}, 500);
	// 滚动逻辑，滚动到某个位置更新数据
	const onPopupScroll = React.useCallback(throttle((e: any) => {
		let target = e.target as any;
		if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 30) {
			const totalPage = Math.ceil(total / pagination.pageSize);
			if (totalPage <= pagination.page) return;
			setPagination(draft => {
				draft.page = pagination.page + 1;
			});
		}
	}, 500), [throttle, response, pagination]);

	useEffect(() => {
		return () => {
			onPopupScroll.cancel();
			onSerach.cancel();
		};
	}, []);

	useEffect(() => {
		if (isEqual(extensionParams, prevParams.current)) {
			prevParams.current = extensionParams;
			if (extensionChangeClear) {
				if (mode == 'multiple') return onChange?.([]);
				onChange && onChange('');
			}
		}
	}, [extensionChangeClear, ...Object.keys(extensionParams).map(key => extensionParams[key])]);

	const reset = () => {
		setPagination(draft => {
			draft.page = 1;
			draft[searchKey] = '';
		});
	};

	const _onBlur = (e) => {
		onBlur?.(e);
		reset();
	};

	return (
		<Select
			disabled={disabled}
			className={className}
			placeholder={placeholder}
			value={value}
			loading={isValidating}
			onChange={onSelectChange}
			showSearch
			allowClear
			onSearch={onSerach}
			onClear={reset}
			style={style}
			dropdownMatchSelectWidth={dropdownMatchSelectWidth}
			mode={mode}
			optionFilterProp="label"
			onBlur={_onBlur}
			onPopupScroll={(e) => {
				e.persist();
				onPopupScroll(e);
			}}
			{...rest}
		>
			{hasAllField && <Select.Option value=''>{allSelectLabel}</Select.Option>}
			{datas.map((i, idx) => (
				<Select.Option
					disabled={disabledFiled.includes(i[labelValueKey])}
					key={idx}
					value={i[labelValueKey]}
					label={i[labelKey]}
					item={i}
				>
					{i[labelKey] || i[labelValueKey]}
				</Select.Option>
			))}
		</Select>
	);
});

export default AsyncSelectPlus;
