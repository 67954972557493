import React from 'react'
import { isArray, isEmpty } from 'lodash'

interface MapObject {
	[x: string]: any
}

type Record = MapObject

export interface DrawerProps {
	// 弹层类型
	// 判定条件是operationInfo.records 是否大于1
	type: 'single' | 'multiple'
	// visible
	visible: boolean
	// record值
	// 从判定条件是operationInfo.records 取的。方便使用
	record: Record
	records: Record[]
	// 是否为空来判断编辑还是新建
	isEmptyRecord: boolean
	/**
	 * 打开弹层
	 */
	onOpen: (records: Record | Record[]) => void
	/**
	 * 弹层操作完成，并刷新api，需要和usePagination或者useRequest配合
	 */
	onFinishAndRevalidate: () => void
	/**
	 * 关闭
	 */
	onClose: () => void
}

type Revalidate = () => Promise<boolean>
const useDrawer = (revalidate?: Revalidate): DrawerProps => {

	const [operationInfo, setOperationInfo] = React.useState<{
		visible: boolean,
		records: Record[]
	}>({
		visible: false,
		records: []
	})

	const onClose = React.useCallback(() => {
		setOperationInfo({
			visible: false,
			records: []
		})
	}, [setOperationInfo])

	const onOpen = React.useCallback((records) => {
		const nextRecord = isArray(records) ? records : [records]
		setOperationInfo({
			visible: true,
			records: nextRecord
		})
	}, [setOperationInfo])

	const onFinishAndRevalidate = () => {
		if (!revalidate) throw new Error('[onFinish] 方法需要和[usePagination]配合， 请检查[useDrawer]是否传入revalidate')
		revalidate()
		onClose()
	}

	const getRecord = React.useMemo(() => {
		return operationInfo.records[0] || {}
	}, [operationInfo])

	const type = operationInfo.records.length > 1 ? 'multiple' : 'single'

	const isEmptyRecord = isEmpty(getRecord)
	
	return {
		type,
		isEmptyRecord,
		visible: operationInfo.visible,
		record: getRecord,
		records: operationInfo.records,
		onOpen,
		onFinishAndRevalidate,
		onClose
	}
}

export default useDrawer