
import { apis } from 'src/apiStore'

const useApiSelector = () => {
	return apis
}

export default useApiSelector


