import React  from "react";
import {PageHeader, Card, Space, Input, Button, Table, Select} from 'antd'
import useColumns from './useColumns'
import { useDrawer } from '../../../hooks'
import Create from "./Create";
import { useApiSelector } from 'src/hooks'
import { useRequest } from '@friday/async'
import { useImmer }  from '@friday/immer'
import { get } from 'lodash'
const Index = () => { 

    const apis = useApiSelector()

    const [state, setState] = useImmer({
        key: '',
        state: 0,
    })

    const { responseJson, isValidating, revalidate, pagination} = useRequest(apis.admin.topicList({...state}), {paginated: true})

    const list = get(responseJson, 'data.list')

    const drawerProps = useDrawer(revalidate)

    const columns = useColumns(drawerProps, revalidate)

    return (
        <div>
            <PageHeader 
                title='话题管理'
                ghost={false}
                className='mg-b-10'
            />
            <Card hoverable>
                <div className='clearfix mg-b-20'>
                    <Space>
                        <span>审核状态</span>
                        <Select style={{width: '180px'}}  value={state.state} allowClear placeholder='请选择审核状态' onChange={(e) => setState(dart => { dart.state = e as any})} >
                            <Select.Option value={0}>全部</Select.Option>
                            <Select.Option value={1}>待审核</Select.Option>
                            <Select.Option value={2}>已通过</Select.Option>
                            <Select.Option value={3}>已拒绝</Select.Option>
                        </Select>
                        <Input.Search 
                            style={{width: '180px'}} 
                            placeholder="请输入搜索内容" 
                            onChange={(e) => setState(dart => { dart.key = e.target.value })} 
                        />
                    </Space>
                </div>
                <Table 
                    columns={columns as any}
                    dataSource={list}
                    loading={isValidating}
                    size={'middle'}
                    scroll={{
                        x: 'max-content'
                    }}
                    rowKey={'id'}
                    pagination={pagination as any}
                />
                {drawerProps.visible && 
                    <Create drawerProps={drawerProps} />
                }
            </Card>
        </div>
    )
}

export default Index