import React from "react";
import { Divider, Space, Modal, Button, message } from 'antd'


export const statusMap = {
    0: '全部',
    1: "待审核",
    2: "已通过",
    3: "已拒绝"
}
const useColumns = (drawerProps, revalidate) => {

    return React.useMemo(() => {
        const columns = [
        {
            title: '内容ID',
            dataIndex: 'id'
        }, {
            title: '内容标题',
            dataIndex: 'title'
        }, {
            title: '作者',
            dataIndex: 'operator',
            render: (text, record) => {
                return <div>
                     <img src={record.author.avatar} style={{ width: 30, height: 30, borderRadius: '100%', marginRight: 4}} />
                     {record.author.nickname}
                </div>
            }
        }, {
            title: '审核状态',
            dataIndex: 'status',
            render: ( text) => statusMap[text]
        },{
            title: '创建时间',
            dataIndex: 'createdAt',
        },{
            title: '更新时间',
            dataIndex: 'modifiedAt',
        },{
            title: '操作',
            dataIndex: 'opr',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <Space split={<Divider />}>  
                        <Button type='primary' size={'small'} onClick={() => drawerProps.onOpen(record)}>
                            审核
                        </Button>
                    </Space>
                )
            }
        }]
    
        return columns
    
    }, [])
} 

export default useColumns